import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import {useAuth} from './AuthProvider'

const Detailsprivateroute = () => {
    const {TokenValidate,Role} = useAuth();
    if (!TokenValidate()) 
      return <Navigate to="/" />;
    if (Role!=0&&Role!=1 && Role!=2) 
        return <Navigate to="/" />;
    return <Outlet />;
}

export default Detailsprivateroute
