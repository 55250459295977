import React from 'react';
import Chart from 'react-apexcharts';

const StackedAreaChart = () => {
  // Define chart options and series data
  const options = {
    chart: {
      type: 'area',
      stacked: true, // Enable stacking
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false, // Disable labels inside the chart
    },
    stroke: {
      curve: 'smooth', // Smooth curve for lines
    },
    xaxis: {
      type: 'datetime', // Use datetime for x-axis
      categories: [
        '2024-01-01',
        '2024-01-02',
        '2024-01-03',
        '2024-01-04',
        '2024-01-05',
        '2024-01-06',
        '2024-01-07',
      ],
    },
    yaxis: {
      title: {
        text: 'Values',
      },
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy', // Date format for tooltip
      },
    },
    fill: {
      opacity: 1, // Opacity of the stacked areas
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
    },
  };

  // Sample data for the chart
  const series = [
    {
      name: 'Product A',
      data: [34, 44, 54, 21, 12, 43, 33],
    },
    {
      name: 'Product B',
      data: [32, 33, 41, 32, 24, 33, 26],
    },
    {
      name: 'Product C',
      data: [21, 22, 33, 44, 22, 32, 45],
    },
  ];

  return (
    <div>
      <Chart options={options} series={series} type="area" height={350} />
    </div>
  );
};

export default StackedAreaChart;
