import React, { useState,useEffect } from "react";
import BreadcrumpsPagetitle from "../../GenricComponnets/BreadcrumpsPagetitle";
import {
  showErrorAlert,
  showAlert,
  showSuccessAlert,
} from "../../UtilisComponent/sweatalert";
import useApiService from "../../../Services/ApiService/apiService";
import LoadingPopup from "../../UtilisComponent/LoadingComponent/LoadingPopUp";
import { useAuth } from "../../../Services/Authservice/AuthProvider";

const CreateSkinCollectionType = () => {
    let Heading = "Create Mobile Skin Collection Type";
    let childPath = [
      { Name: "Home", Path: "#" },
      { Name: "Admin", Path: "#" },
    ];
    
  const [isloading, setisloading] = useState(false);
  const [CollectionData, setCollectionData] = useState([]);
  const {getData, postData } = useApiService();
  const { Token } = useAuth();
  
  useEffect(() => {
    fetchMobileskinCollectionData();
  }, []);

  const fetchMobileskinCollectionData = async () => {
    setisloading(true)
    setCollectionData([])
    try {
      const response=await getData(`Categories/GetMobileskinCategories`,Token);
      if(response.statusCode===200) {
        const res = response.data;
        const status = res.status;
        if(status){
          setCollectionData(res.data)
        }
        else{
          showAlert(res.message);
        }
      }
      else{
        showAlert(response.responseMessage);
      }
    } catch (error) {
     showErrorAlert(error);
    }
    finally{
      setisloading(false)
    }
  };
  // Define initial form state
  const initialFormState = {
    CategoryID: 0,
    CategoriesTypeName: '',
    CategoriesTypeImage: Math.random().toString(),
    IsActive: true,
    file: null, // Store the file here
  };

    const [formData, setFormData] = useState(initialFormState);
    
    // Validation FORM DATA
    const validateFormData = () => {
      const response ={status :false,msg:'NOT VALID'}
      if (formData.CategoryID === 0) {
        response.msg="Please Select Collection.";
        return response;
      }
      if (!formData.CategoriesTypeName) {
       response.msg="Collections Type Name cannot be null.";
        return response;
      }
      if (!formData.CategoriesTypeImage) {
       response.msg="CollectionsTypeImage cannot be null.";
        return response;
      }
      if (formData.file === null) {
        response.msg="File must be selected.";
        return response;
      }
      response.msg='';
      response.status=true;
      return response; // Validation successful
    };

    const handleFileChange = (e) => {
      setFormData({
        ...formData,
        file: e.target.files[0],
      });
    };

  const submit = async (e) => {
    debugger;
    e.preventDefault();
    setisloading(true)
    try {
      const VALIDfORMDATA= validateFormData();
      if (VALIDfORMDATA.status) {
        // Create FormData object
        const data = new FormData();
        data.append('CategoryID', formData.CategoryID);
        data.append('CategoriesTypeName', formData.CategoriesTypeName);
        data.append('CategoriesTypeImage', formData.CategoriesTypeImage);
        data.append('IsActive', formData.IsActive);
        data.append('file', formData.file);
       
        const response = await postData(
          `Distributor/InsertMobileskinCategoryType`,
          data,
          true,
          Token
        );
        if (response) {
          if (response.statusCode === 200) {
            const res = response.data;
            const status = res.status;
            if (status) {
              setFormData(initialFormState);
              showSuccessAlert("Wooho !! New Collection Added.");
            } else {
              showErrorAlert(res.message);
            }
          } else {
            showAlert(response.responseMessage);
          }
        } else {
          showErrorAlert("Something went wrong ,please Contact To It Team.");
        }
      }
     else {
        showAlert(VALIDfORMDATA.msg);
      }
    } catch (err) {
      showErrorAlert(err);
    } finally {
      setisloading(false);
    }
  };
  const reset = async (e) => {
    e.preventDefault();
    setFormData(initialFormState);
  };
    return (
      <main id="main" class="main">
      <LoadingPopup show={isloading} />
        <BreadcrumpsPagetitle heading={Heading} items={childPath} />
        <section class="section dashboard">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title"></h5>

                  <form class="row g-3">

                    <div class="col-md-4">
                    <div class="form-floating mb-3">
                      <select class="form-select" id="floatingSelect" aria-label="State"
                      value={formData.CategoryID}
                      onChange={(e) => setFormData({ ...formData, CategoryID: parseInt(e.target.value) })}
                    >
                        <option selected value={0}>Select Collection</option>
                        {CollectionData.map((collection) => (
                          <option key={collection.CategoryID} value={collection.CategoryID}>
                            {collection.CategoryName}
                          </option>
                        ))}
                      </select>
                      <label for="floatingSelect">Collection</label>
                    </div>
                  </div>

                    <div class="col-md-4">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          value={formData.CategoriesTypeName}
                          onChange={(e) => setFormData({ ...formData, CategoriesTypeName: e.target.value })}
                          name="collectionsName"
                          placeholder="Collection Name"
                        />
                        <label for="floatingPassword">Collection Name</label>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-floating">
                      <input  class="form-control" type="file" onChange={handleFileChange} />
                      <label>File Upload:</label>
                      </div>
                    </div>
                    
                      <div class="text-center">
                        <button
                          type="submit"
                          class="btn btn-primary"
                          onClick={submit}
                        >
                          Submit
                        </button>
                        &nbsp;
                        <button
                          type="reset"
                          class="btn btn-secondary"
                          onClick={reset}
                        >
                          Reset
                        </button>
                      </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
}

export default CreateSkinCollectionType
