import config from "../../config";

const BASE_URL = `${config.apiUrl}`;

const useApiService = () => {
  // Generic GET request (with optional token)
  const getData = async (endpoint, token = null) => {
    try {
      // Set up headers conditionally if a token is provided
      const headers = token ? { Authorization: `Bearer ${token}` } : {};

      const response = await fetch(`${BASE_URL}${endpoint}`, { headers });

      // Extract and format the response
      const data = await response.json();
      const responseObject = {
        statusCode: response.status,
        responseMessage: response.ok ? 'Success' : data.message || 'Error fetching data',
        data: response.ok ? data : null,
      };

   
      if (!response.ok) {
        // If response is not ok, throw the formatted error
        throw new Error(responseObject.responseMessage || 'Error fetching data');
      }

      return responseObject;
    } catch (error) {
      // Format the error message and return
      return {
        statusCode: 500, // Internal server error
        responseMessage: error.message || 'Internal server error',
        data: null,
      };
    }
  };

  // Generic POST request (with optional token, handles both JSON and FormData)
  const postData = async (endpoint, data, isFormData = false, token = null) => {
    try {
      // Set up headers conditionally based on content type and token
      const headers = {
        ...(isFormData ? {} : { 'Content-Type': 'application/json' }),
        ...(token && { Authorization: `Bearer ${token}` }),
      };

      const response = await fetch(`${BASE_URL}${endpoint}`, {
        method: 'POST',
        headers,
        body: isFormData ? data : JSON.stringify(data),
      });

      // Extract and format the response
      const responseData = await response.json();
      const responseObject = {
        statusCode: response.status,
        responseMessage: response.ok ? 'Success' : responseData.message || 'Error posting data',
        data: response.ok ? responseData : null,
      };

     

      if (!response.ok) {
        // If response is not ok, throw the formatted error
        throw new Error(responseObject.responseMessage || 'Error posting data');
      }

      return responseObject;
    } catch (error) {
      // Format the error message and return
      return {
        statusCode: 500, // Internal server error
        responseMessage: error.message || 'Internal server error',
        data: null,
      };
    }
  };

  return { getData, postData };
};

export default useApiService;
