import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from './Layouts/Header'
import Footer from './Layouts/Footer'
import Aside from './Layouts/Aside'
const Mainlayouts = ({children}) => {
  return (
    <>
    <Header/>
    <Aside/>
    <Outlet/>
    {children}
    <Footer/>
      
    </>
  )
}

export default Mainlayouts
