import React, { useState, useEffect } from "react";
import BreadcrumpsPagetitle from '../../GenricComponnets/BreadcrumpsPagetitle'
import {
  showErrorAlert,
  showAlert
} from "../../UtilisComponent/sweatalert";
import useApiService from "../../../Services/ApiService/apiService";
import LoadingPopup from "../../UtilisComponent/LoadingComponent/LoadingPopUp";
import { useAuth } from "../../../Services/Authservice/AuthProvider";
import DataTable from "../../UtilisComponent/DataTable";

const MobileBrandModelsDetails = () => {
    let Heading = "Mobile Brand Model Details";
    let childPath = [
      { Name: "Home", Path: "#" },
      { Name: "Details Report", Path: "#" },
    ];
    const [isloading, setisloading] = useState(false);
    const { getData } = useApiService();
    const { Token } = useAuth();
    useEffect(() => {
      fetchCategoryData();
    },[]);
    // Define columns from the keys of the data dynamically
    const excludeColumns = ["ResponseCode","ResponseMsg", "IsActive"];
    const [CategorySubType, setCategorySubType] = useState([]);

    const fetchCategoryData = async () => {
      setisloading(true)
      setCategorySubType([])
      try {
        const response=await getData(`Collections/GetMobileBrandSubCollectionTypeById`,Token);
        if(response.statusCode===200) {
          const res = response.data;
          const status = res.status;
          if(status){
            setCategorySubType(res.data)
          }
          else{
            showAlert(res.message);
          }
        }
        else{
          showAlert(response.responseMessage);
        }
      } catch (error) {
       showErrorAlert(error);
      }
      finally{
        setisloading(false)
      }
    };
    return (
      <main id="main" class="main">
         <LoadingPopup show={isloading} />
        <BreadcrumpsPagetitle heading={Heading} items={childPath} />
          <section class="section">
          <div class="row">
          <div class="col-lg-12">
              <DataTable data={CategorySubType} Title={'Mobiles Brand Model'} excludeColumns={excludeColumns} 
                showActions={false}
                />
          </div>
        </div>
        </section>

      </main>
    );
}

export default MobileBrandModelsDetails
