// src/utils/swalUtils.js
import swal from 'sweetalert';

// Utility function for showing alerts
export const showAlert = (title, text, icon = 'info') => {
  return swal({
    title,
    text,
    icon
  });
};

// Example of another utility function
export const showSuccessAlert = (message) => {
  return showAlert('Success', message, 'success');
};

export const showErrorAlert = (message) => {
  return showAlert('Error', message, 'error');
};
