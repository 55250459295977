import React from "react";
import MUIDataTable from "mui-datatables";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility"; // Icon for "Show"
import config from "../../config";
const DataTable = ({
  Title,
  data,
  excludeColumns = [],
  onEdit,
  onDelete,
  showActions = true,
  imageUrlColumn = "categoryimagepath", // Dynamic column name for image URL
  showEditDeleteCondition, // Dynamic condition to show Edit/Delete buttons
  showImageCondition
}) => {
 
  // Function to generate the columns dynamically
  const generateColumns = (data) => {
    if (!data.length) return [];

    // Generate dynamic columns excluding specified columns
    const dynamicColumns = Object.keys(data[0])
      .filter((key) => !excludeColumns.includes(key))
      .map((key) => ({
        name: key,
        label: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize column names
      }));

    // Add the actions column at the end if showActions is true
    if (showActions) {
      const actionColumn = {
        name: "Actions",
        label: "Actions",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const rowIndex = tableMeta.rowIndex;
            const rowData = data[rowIndex]; // Get row data based on index
            const imageUrl = rowData[imageUrlColumn]; // Get the image URL dynamically based on props

            // Call prop functions to determine conditions for showing buttons
            const showEditDelete = showEditDeleteCondition; // Condition from props
            const showImage = showImageCondition; // Condition from props

            return (
              <div>
                {/* Conditionally show Edit and Delete buttons */}
                {showEditDelete && (
                  <>
                    <IconButton
                      aria-label="edit"
                      onClick={() => onEdit(rowData, rowIndex)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={() => onDelete(rowData, rowIndex)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </>
                )}

                {/* Conditionally show the "Show" button to open image */}
                {showImage && imageUrl && (
                  <IconButton
                    aria-label="show"
                    onClick={() => window.open(`${config.apiHostUrl}${imageUrl}`, "_blank")} // Open image in a new tab
                  >
                    <VisibilityIcon />
                  </IconButton>
                )}
              </div>
            );
          },
        },
      };

      // Add the action column to the dynamic columns array
      dynamicColumns.push(actionColumn);
    }

    return dynamicColumns;
  };
 
  // Table options
  const options = {
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none", // Disable row selection
    download: true, // Enables CSV download
    print: true, // Enables print option
    search: true, // Enables search functionality
    pagination: true, // Enables pagination
    rowsPerPage: 5, // Set initial rows per page
    rowsPerPageOptions: [5, 10], // Options for 5 or 10 rows per page
    
  };

  return (
    <MUIDataTable
      title={Title}
      data={data}
      columns={generateColumns(data)}
      options={options}
    />
  );
};

export default DataTable;
