import React from 'react'
import './css/maintanence.css'
const Maintenance = () => {
  return (
    <div class="maintenance">
    <div class="maintenance_contain">
      <img src="https://demo.wpbeaveraddons.com/wp-content/uploads/2018/02/main-vector.png" alt="maintenance"/>
      <span class="pp-infobox-title-prefix">WE ARE COMING SOON</span>
      <div class="pp-infobox-title-wrapper">
            <h3 class="pp-infobox-title">The website under maintenance!</h3>
        </div> 
    <div class="pp-infobox-description">
          <p>Someone has kidnapped our site. We are negotiation ransom and<br/>will resolve this issue in 24/7 hours</p>			</div>    
      <span class="title-text pp-primary-title">We are social</span>
      <div class="pp-social-icons pp-social-icons-center pp-responsive-center" style={{display:'none'}}>
      <span class="pp-social-icon">
         
          <a itemprop="sameAs" href="#" target="_blank" title="Facebook" aria-label="Facebook" role="button">
              <i class="fa fa-facebook"></i>
          </a>
      </span>
      <span class="pp-social-icon">
       
          <a itemprop="sameAs" href="#" target="_blank" title="Twitter" aria-label="Twitter" role="button">
              <i class="fa fa-twitter"></i>
          </a>
      </span>
      <span class="pp-social-icon">
          
          <a itemprop="sameAs" href="#" target="_blank" title="Google Plus" aria-label="Google Plus" role="button">
              <i class="fa fa-google-plus"></i>
          </a>
      </span>
      <span class="pp-social-icon">
          <a itemprop="sameAs" href="#" target="_blank" title="LinkedIn" aria-label="LinkedIn" role="button">
              <i class="fa fa-linkedin"></i>
          </a>
      </span>
  </div>
    </div>
  </div>
  )
}

export default Maintenance
