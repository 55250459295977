import React, { useState } from "react";
import BreadcrumpsPagetitle from "../../GenricComponnets/BreadcrumpsPagetitle";
import {
  showErrorAlert,
  showAlert,
  showSuccessAlert,
} from "../../UtilisComponent/sweatalert";
import useApiService from "../../../Services/ApiService/apiService";
import LoadingPopup from "../../UtilisComponent/LoadingComponent/LoadingPopUp";
import { useAuth } from "../../../Services/Authservice/AuthProvider";

const CreateSkinCollection = () => {
  let Heading = "Create Mobile Skin Collection";
  let childPath = [
    { Name: "Home", Path: "#" },
    { Name: "Admin", Path: "#" },
  ];

  const [isloading, setisloading] = useState(false);
  const { postData } = useApiService();
  const { Token } = useAuth();
  
// Define initial form state
const initialFormState = {
  CategoryName: '',
  CategoryImg: Math.random().toString(),
  IsActive: true,
  file: null, // Store the file here
};

 const [formData, setFormData] = useState(initialFormState);
   // Validation FORM DATA
 const validateFormData = () => {
    const response ={status :false,msg:'NOT VALID'}
   
    if (!formData.CategoryName) {
     response.msg="Please Fill Mobile skin Name.";
      return response;
    }
    if (!formData.CategoryImg) {
     response.msg="CategoriesSubTypeImage cannot be null.";
      return response;
    }
    if (formData.file === null) {
      response.msg="File must be selected.";
      return response;
    }
    response.msg='';
    response.status=true;
    return response; // Validation successful
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      file: e.target.files[0],
    });
  };

  const submit = async (e) => {
    debugger;
    e.preventDefault();
    setisloading(true)
    try { const VALIDfORMDATA= validateFormData();
      if (VALIDfORMDATA.status)  {
        const data = new FormData();
        data.append('CategoryName', formData.CategoryName);
        data.append('CategoryImg', formData.CategoryImg);
        data.append('IsActive', formData.IsActive);
        data.append('file', formData.file);
       
        const response = await postData(
          `Distributor/InsertMobileskinCategory`,
          data,
          true,
          Token
        );
        if (response) {
          if (response.statusCode === 200) {
            const res = response.data;
            const status = res.status;
            if (status) {
              setFormData(initialFormState);
              showSuccessAlert("Wooho !! New Collection Added.");
            } else {
              showErrorAlert(res.message);
            }
          } else {
            showAlert(response.responseMessage);
          }
        } 
        else {
          showErrorAlert("Something went wrong ,please Contact To It Team.");
        }
      } else {
        showAlert(VALIDfORMDATA.msg);
      }
    } catch (err) {
      showErrorAlert(err);
    } finally {
      setisloading(false);
    }
  };
  const reset = async (e) => {
    e.preventDefault();
    setFormData(initialFormState);
  };
  return (
    <div>
      <LoadingPopup show={isloading} />
      <main id="main" class="main">
        <BreadcrumpsPagetitle heading={Heading} items={childPath} />
        <section class="section dashboard">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title"></h5>

                  <form class="row g-3">
                    <div class="col-md-4">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          value={formData.CategoryName}
                          onChange={(e) => setFormData({ ...formData, CategoryName: e.target.value })}
                          name="collectionsName"
                          placeholder="Collection Name"
                        />
                        <label for="floatingPassword">Collection Name</label>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-floating">
                      <input  class="form-control" type="file" onChange={handleFileChange} />
                      <label>File Upload:</label>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="text-center">
                        <button
                          type="submit"
                          class="btn btn-primary"
                          onClick={submit}
                        >
                          Submit
                        </button>
                        &nbsp;
                        <button
                          type="reset"
                          class="btn btn-secondary"
                          onClick={reset}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* <!-- End #main --> */}
    </div>
  );
};

export default CreateSkinCollection;
