import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../Services/Authservice/AuthProvider";
const Header = () => {  
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const auth=useAuth();
  let UserName = localStorage.getItem("UserName");
  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
    document.body.classList.toggle('toggle-sidebar');
  };

    //SCRENN MAXIMIZE
  
    const launchFullscreen = () => {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      }
    };
    return (
      <div>
         <header id="header" class="header fixed-top d-flex align-items-center">
  
  <div class="d-flex align-items-center justify-content-between">
    <a href="index.html" class="logo d-flex align-items-center">
    <h2 className='.btn-primary' style={{fontWeight:'bold'}}>ArtWrap</h2>{/* <img src="assets/img/Virtuzo.png" alt=""></img> */}
      <span class="d-none d-lg-block"></span>
    </a>
    <i class="bi bi-list toggle-sidebar-btn" onClick={toggleSidebar}></i>
  </div>
  
  <div class="search-bar" style={{display:'none'}}>
    <form class="search-form d-flex align-items-center" method="POST" action="#">
      <input type="text" name="query" placeholder="Search" title="Enter search keyword"></input>
      <button type="submit" title="Search"><i class="bi bi-search"></i></button>
    </form>
  </div>
  
  <nav class="header-nav ms-auto">
    <ul class="d-flex align-items-center">
  
      <li class="nav-item d-block d-lg-none"  style={{display:'none'}}>
        <a class="nav-link nav-icon search-bar-toggle " href="#">
          <i class="bi bi-search"></i>
        </a>
      </li>
  
      <li class="nav-item dropdown"  style={{display:'none'}}>
  
        <a class="nav-link nav-icon" href="#" onClick={launchFullscreen} >
          <i class="bi bi-arrows-fullscreen"></i>
          
        </a></li>
      <li class="nav-item dropdown" style={{display:'none'}}>
  
        <a class="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
          <i class="bi bi-bell"></i>
          <span class="badge bg-primary badge-number">4</span>
        </a>
  
        <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
          <li class="dropdown-header">
            You have 4 new notifications
            <a href="#"><span class="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
          </li>
          <li>
            <hr class="dropdown-divider"></hr>
          </li>
  
          <li class="notification-item">
            <i class="bi bi-exclamation-circle text-warning"></i>
            <div>
              <h4>Lorem Ipsum</h4>
              <p>Quae dolorem earum veritatis oditseno</p>
              <p>30 min. ago</p>
            </div>
          </li>
  
          <li>
            <hr class="dropdown-divider"></hr>
          </li>
  
          <li class="notification-item">
            <i class="bi bi-x-circle text-danger"></i>
            <div>
              <h4>Atque rerum nesciunt</h4>
              <p>Quae dolorem earum veritatis oditseno</p>
              <p>1 hr. ago</p>
            </div>
          </li>
  
          <li>
            <hr class="dropdown-divider"></hr>
          </li>
  
          <li class="notification-item">
            <i class="bi bi-check-circle text-success"></i>
            <div>
              <h4>Sit rerum fuga</h4>
              <p>Quae dolorem earum veritatis oditseno</p>
              <p>2 hrs. ago</p>
            </div>
          </li>
  
          <li>
            <hr class="dropdown-divider"></hr>
          </li>
  
          <li class="notification-item">
            <i class="bi bi-info-circle text-primary"></i>
            <div>
              <h4>Dicta reprehenderit</h4>
              <p>Quae dolorem earum veritatis oditseno</p>
              <p>4 hrs. ago</p>
            </div>
          </li>
  
          <li>
            <hr class="dropdown-divider"></hr>
          </li>
          <li class="dropdown-footer">
            <a href="#">Show all notifications</a>
          </li>
  
        </ul>
  
      </li>
  
      <li class="nav-item dropdown" style={{display:'none'}}>
  
        <a class="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
          <i class="bi bi-chat-left-text"></i>
          <span class="badge bg-success badge-number"></span>
        </a>
  
        <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow messages">
          <li class="dropdown-header" >
            You have 3 new messages
            <a href="#"><span class="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
          </li>
          <li>
            <hr class="dropdown-divider"></hr>
          </li>
  
          <li class="message-item">
            <a href="#">
              <img src="#" alt="" class=" bi bi-clock"></img>
              <div>
                <h4>Retailer</h4>
                <p>Retailer support business hours -</p>
                <p> (Mon - Sun 9 am to 6 pm PST)</p>
              </div>
            </a>
          </li>
          <li>
            <hr class="dropdown-divider"></hr>
          </li>
  
          <li class="message-item">
            <a href="#">
              <img src="#" alt="" class="bi bi-clock"></img>
              <div>
                <h4>Phone</h4>
                <p>Call Retailer support -</p>
                <p> +1 (213) 213 5880</p>
              </div>
            </a>
          </li>
          <li>
            <hr class="dropdown-divider"></hr>
          </li>
  
          <li class="message-item">
            <a href="#">
              <img src="assets/img/messages-3.jpg" alt="" class="rounded-circle"></img>
              <div>
                <h4>David Muldon</h4>
                <p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>
                <p>8 hrs. ago</p>
              </div>
            </a>
          </li>
          <li>
            <hr class="dropdown-divider"></hr>
          </li>
  
          <li class="dropdown-footer">
            <a href="#">Show all messages</a>
          </li>
  
        </ul>
  
      </li>
  
      
  
      <li class="nav-item dropdown pe-3 ">
  
  <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" >
  <i class="bi bi-person"></i>
    <span class="d-none d-md-block ps-2">{auth.user}</span>
  </a>
  </li>
  
      <li class="nav-item dropdown pe-3" style={{display:'none'}}>
  
        <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
        <i class="bi bi-person"></i>
          <span class="d-none d-md-block dropdown-toggle ps-2">{UserName}</span>
          
        </a>
        <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
          <li class="dropdown-header">
            <h6>{UserName}</h6>
            <span>Web Designer</span>
          </li>
          <li>
            <hr class="dropdown-divider"></hr>
          </li>
  
          <li>
            <a class="dropdown-item d-flex align-items-center" href="users-profile.html">
              <i class="bi bi-person"></i>
              <span>My Profile</span>
            </a>
          </li>
          <li>
            <hr class="dropdown-divider"></hr>
          </li>
  
          <li>
            <a class="dropdown-item d-flex align-items-center" href="users-profile.html">
              <i class="bi bi-gear"></i>
              <span>Account Settings</span>
            </a>
          </li>
          <li>
            <hr class="dropdown-divider"></hr>
          </li>
  
          <li>
            <a class="dropdown-item d-flex align-items-center" href="pages-faq.html">
              <i class="bi bi-question-circle"></i>
              <span>Need Help?</span>
            </a>
          </li>
          <li>
            <hr class="dropdown-divider"></hr>
          </li>
  
          <li>
            <Link to="/" class="dropdown-item d-flex align-items-center"  >
              <i class="bi bi-box-arrow-right"></i>
              <span>Sign Out</span>
            </Link>
          </li>
  
        </ul>
      </li>
      <li class="nav-item dropdown pe-3 ">
  
  <a href="#" className="nav-link nav-profile d-flex align-items-center pe-0" onClick={()=>auth.logOut()}>
  <i className="bi bi-box-arrow-right"></i>
  <span className="d-none d-md-block ps-2" >Log Out</span>
  </a>
  
  </li>
    </ul>
  </nav>
  
  </header>
      </div>
    )
}

export default Header
