import React from 'react'
import PropsMobileproductDetails from "../../GenricComponnets/PropsMobileproductDetails";


const MobileCustomizeSkinProductDetails = () => {
  return (
    <div>
        <PropsMobileproductDetails header={"Mobile Custmoize Skin Product Details"} endpoint={'GetMobileskinCategorySubTypespod'}/>
    </div>
  )
}

export default MobileCustomizeSkinProductDetails
