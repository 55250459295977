import React, { useState,useEffect } from "react";
import BreadcrumpsPagetitle from "../../GenricComponnets/BreadcrumpsPagetitle";
import {
  showErrorAlert,
  showAlert,
  showSuccessAlert,
} from "../../UtilisComponent/sweatalert";
import useApiService from "../../../Services/ApiService/apiService";
import LoadingPopup from "../../UtilisComponent/LoadingComponent/LoadingPopUp";
import { useAuth } from "../../../Services/Authservice/AuthProvider";

const CreateMobileBrandModel = () => {
    let Heading = "Create Mobile Brand Model";
    let childPath = [
      { Name: "Home", Path: "#" },
      { Name: "Admin", Path: "#" },
    ];
    const [isloading, setisloading] = useState(false);
    const [categorytypedata, setcategorytypedata] = useState([]);
    const {getData, postData } = useApiService();
    const { Token } = useAuth();
    const[collectionsID,setcollectionsID]=useState(0);
    const[collectionsTypeName,setcollectionsTypeName]=useState('');
       
    useEffect(() => {
      fetchCobileBrandCollectionsData();
    },[]);
  
    const fetchCobileBrandCollectionsData = async () => {
      setisloading(true)
      setcategorytypedata([])
      try {
        const response=await getData(`Collections/GetMobileBrandCollections`,Token);
        if(response.statusCode===200) {
          const res = response.data;
          const status = res.status;
          if(status){
            setcategorytypedata(res.data)
          }
          else{
            showAlert(res.message);
          }
        }
        else{
          showAlert(response.responseMessage);
        }
      } catch (error) {
       showErrorAlert(error);
      }
      finally{
        setisloading(false)
      }
    };
      // Validation FORM DATA
      const validateFormData = () => {
        const response ={status :false,msg:'NOT VALID'}
        if (collectionsID === 0) {
          response.msg="Please Select Mobile Brand.";
          return response;
        }
        if (!collectionsTypeName) {
         response.msg="Please Fill Mobile Brand Collection Name.";
          return response;
        }
        response.msg='';
        response.status=true;
        return response; // Validation successful
      };
  
     
    const submit = async (e) => {
      debugger;
      e.preventDefault();
      setisloading(true)
      try {
        const VALIDfORMDATA= validateFormData();
        if (VALIDfORMDATA.status) {
          // Create FormData object
          let data = {
            "collectionsID": collectionsID,
            "collectionsTypeName": collectionsTypeName,
            "isActive": true
          };
         
          const response = await postData(
            `Distributor/InsertMobileBrandSubCollection`,
            data,
            false,
            Token
          );
          if (response) {
            if (response.statusCode === 200) {
              const res = response.data;
              const status = res.status;
              if (status) {
                setcollectionsID(0)
                 setcollectionsTypeName('');
                showSuccessAlert("Wooho !! New Collection Added.");
              } else {
                showErrorAlert(res.message);
              }
            } else {
              showAlert(response.responseMessage);
            }
          } else {
            showErrorAlert("Something went wrong ,please Contact To It Team.");
          }
        }
       else {
          showAlert(VALIDfORMDATA.msg);
        }
      } catch (err) {
        showErrorAlert(err);
      } finally {
        setisloading(false);
      }
    };
    const reset = async (e) => {
      e.preventDefault();
      setcollectionsID(0)
      setcollectionsTypeName('');
    };
    return (
      <main id="main" class="main">
      <LoadingPopup show={isloading} />
        <BreadcrumpsPagetitle heading={Heading} items={childPath} />
        <section class="section dashboard">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title"></h5>

                  <form class="row g-3">

                    <div class="col-md-4">
                    <div class="form-floating mb-3">
                      <select class="form-select" id="floatingSelect" aria-label="State"
                      value={collectionsID}
                      onChange={(e) => setcollectionsID(e.target.value)}
                    >
                        <option selected value={0}>Select Mobile Brand</option>
                        {categorytypedata.map((collection) => (
                          <option key={collection.CollectionsID} value={collection.CollectionsID}>
                            {collection.CollectionsName}
                          </option>
                        ))}
                      </select>
                      <label for="floatingSelect">Mobile Brand</label>
                    </div>
                  </div>

                    <div class="col-md-4">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          value={collectionsTypeName}
                          onChange={(e) => setcollectionsTypeName(e.target.value )}
                          name="collectionsName"
                          placeholder="Collection Name"
                        />
                        <label for="floatingPassword">Mobile Brand Model Name</label>
                      </div>
                    </div>

                   
                    <div class="col-md-4">
                      <div class="text-center">
                        <button
                          type="submit"
                          class="btn btn-primary"
                          onClick={submit}
                        >
                          Submit
                        </button>
                        &nbsp;
                        <button
                          type="reset"
                          class="btn btn-secondary"
                          onClick={reset}
                        >
                          Reset
                        </button>
                      </div>
                      </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
}

export default CreateMobileBrandModel
