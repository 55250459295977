import React, { useState } from "react";
import BreadcrumpsPagetitle from "../../GenricComponnets/BreadcrumpsPagetitle";
import {
  showErrorAlert,
  showAlert,
  showSuccessAlert,
} from "../../UtilisComponent/sweatalert";
import useApiService from "../../../Services/ApiService/apiService";
import LoadingPopup from "../../UtilisComponent/LoadingComponent/LoadingPopUp";
import { useAuth } from "../../../Services/Authservice/AuthProvider";

const CreateMobileBrand = () => {
    let Heading = "Create Mobile Brand";
    let childPath = [
      { Name: "Home", Path: "#" },
      { Name: "Admin", Path: "#" },
    ];

    const [isloading, setisloading] = useState(false);
    const [collectionname, setcollectionname] = useState('');
    const {postData } = useApiService();
    const { Token } = useAuth();
   
    const submit = async (e) => {
      debugger;
      e.preventDefault();
      setisloading(true)
      try {
        
        if (collectionname) {
         let req={
          "collectionsName":collectionname,
          "isActive": true
        };
          const response = await postData(
            `Distributor/InsertMobileBrandCollection`,
            req,
            false,
            Token
          );
          if (response) {
            if (response.statusCode === 200) {
              const res = response.data;
              const status = res.status;
              if (status) {
                setcollectionname('');
                showSuccessAlert("Wooho !! New Collection Added.");
              } else {
                showErrorAlert(res.message);
              }
            } else {
              showAlert(response.responseMessage);
            }
          } else {
            showErrorAlert("Something went wrong ,please Contact To It Team.");
          }
        }
       else {
          showAlert('Collection Name is Required');
        }
      } catch (err) {
        showErrorAlert(err);
      } finally {
        setisloading(false);
      }
    };
    const reset = async (e) => {
      e.preventDefault();
      setcollectionname('');
    };
    return (
      <main id="main" class="main">
      <LoadingPopup show={isloading} />
        <BreadcrumpsPagetitle heading={Heading} items={childPath} />
        <section class="section dashboard">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title"></h5>

                  <form class="row g-3">

                    <div class="col-md-6">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          value={collectionname}
                          onChange={(e) => setcollectionname(e.target.value)}
                          name="collectionsName"
                          placeholder="Collection Name"
                        />
                        <label for="floatingPassword">Mobile Brand Name</label>
                      </div>
                    </div>

                    <div class="col-md-6">
                        <div class="text-center">
                          <button
                            type="submit"
                            class="btn btn-primary"
                            onClick={submit}
                          >
                            Submit
                          </button>
                          &nbsp;
                          <button
                            type="reset"
                            class="btn btn-secondary"
                            onClick={reset}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
}

export default CreateMobileBrand
