import React, { useState, useEffect } from "react";
import BreadcrumpsPagetitle from '../../GenricComponnets/BreadcrumpsPagetitle'
import {
  showErrorAlert,
  showAlert
} from "../../UtilisComponent/sweatalert";
import useApiService from "../../../Services/ApiService/apiService";
import LoadingPopup from "../../UtilisComponent/LoadingComponent/LoadingPopUp";
import { useAuth } from "../../../Services/Authservice/AuthProvider";
import DataTable from "../../UtilisComponent/DataTable";
const SkinCollectionDetails = () => {
    let Heading = 'Mobile Skin Collection Details';
    let childPath = [{ Name: 'Home', Path: '#' },{ Name: 'Details Report', Path: '#' }];
    const [isloading, setisloading] = useState(false);
    const { getData } = useApiService();
    const { Token } = useAuth();

    useEffect(() => {
      fetchMenuData();
    },[]);
    // Define columns from the keys of the data dynamically
    const excludeColumns = ["ResponseCode","ResponseMsg", "CategoryImg"];
    const [menuData, setMenuData] = useState([]);
    const fetchMenuData = async () => {
      setisloading(true)
      setMenuData([])
      try {
        const response=await getData(`Categories/GetMobileskinCategories`,Token);
        if(response.statusCode===200) {
          const res = response.data;
          console.log('Mobile skin collection :',res);
          const status = res.status;
          if(status){
            setMenuData(res.data)
          }
          else{
            showAlert(res.message);
          }
        }
        else{
          showAlert(response.responseMessage);
        }
      } catch (error) {
       showErrorAlert(error);
      }
      finally{
        setisloading(false)
      }
    };
   
  return (
    <main id="main" class="main">
      <LoadingPopup show={isloading} />
   <BreadcrumpsPagetitle heading={Heading} items={childPath}/>
   
    {/* <!-- End Page Title --> */}

    <section class="section">
      <div class="row">
        <div class="col-lg-12">
             <DataTable data={menuData} Title={'Collections'} excludeColumns={excludeColumns}  
              imageUrlColumn="CategoryImg"
              showImageCondition={true}
              showActions={true}  />
        </div>
      </div>
    </section>

  </main>

  )
}

export default SkinCollectionDetails
