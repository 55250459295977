import React from "react";
import PropCreteatSkinproduct from "../../GenricComponnets/PropCreteatSkinproduct";

const CreateMobileCoverProduct = () => {

  return (
    <>
    <PropCreteatSkinproduct header={"Create Mobile Skin Product"} endpoint={'InsertMobileskinCategorySubType'} producttype={1}/>
    </>
  );
};

export default CreateMobileCoverProduct;
