import React, { useState, useEffect } from "react";
import BreadcrumpsPagetitle from '../../GenricComponnets/BreadcrumpsPagetitle'
import {
  showErrorAlert,
  showAlert
} from "../../UtilisComponent/sweatalert";
import useApiService from "../../../Services/ApiService/apiService";
import LoadingPopup from "../../UtilisComponent/LoadingComponent/LoadingPopUp";
import { useAuth } from "../../../Services/Authservice/AuthProvider";
import DataTable from "../../UtilisComponent/DataTable";

const SkinCollectionTypeDetails = () => {
    let Heading = "Mobile Skin Collection Type Details";
    let childPath = [
      { Name: "Home", Path: "#" },
      { Name: "Details Report", Path: "#" },
    ];
    const [isloading, setisloading] = useState(false);
    const { getData } = useApiService();
    const { Token } = useAuth();
    useEffect(() => {
      fetchMenuData();
    },[]);
    // Define columns from the keys of the data dynamically
    const excludeColumns = ["ResponseCode","ResponseMsg", "IsActive","CategoriesTypeImage"];
    const [menuData, setMenuData] = useState([]);

    const fetchMenuData = async () => {
      setisloading(true)
      setMenuData([])
      try {
        const response=await getData(`Categories/GetMobileskinCategoryTypeById`,Token);
        if(response.statusCode===200) {
          const res = response.data;
          const status = res.status;
          if(status){
            setMenuData(res.data)
          }
          else{
            showAlert(res.message);
          }
        }
        else{
          showAlert(response.responseMessage);
        }
      } catch (error) {
       showErrorAlert(error);
      }
      finally{
        setisloading(false)
      }
    };

    // const handleEdit = (rowData, rowIndex) => {
    //   console.log("Edit clicked for row:", rowIndex, rowData);
    //   // Perform your edit logic here
    // };
  
    // const handleDelete = (rowData, rowIndex) => {
    //   console.log("Delete clicked for row:", rowIndex, rowData);
    //   // Perform your delete logic here
    // };
  
    
  
    return (
      <main id="main" class="main">
        <LoadingPopup show={isloading} />
        <BreadcrumpsPagetitle heading={Heading} items={childPath} />
        <section class="section">
        <div class="row">
        <div class="col-lg-12">
             <DataTable data={menuData} Title={'Collections'} excludeColumns={excludeColumns} 
              // onEdit={handleEdit}
              // onDelete={handleDelete}
              showActions={true} // Show Edit/Delete/Show buttons
              imageUrlColumn="CategoriesTypeImage" // Pass the dynamic column name for image
              showEditDeleteCondition={false} // Pass the condition for Edit/Delete buttons
              showImageCondition={true}
              />
        </div>
      </div>
        </section>
      </main>
    );
}

export default SkinCollectionTypeDetails
