import React from "react";

const Footer = () => {
  return (
    <section >
      <footer id="footer" class="footer" style={{position:'fixed',bottom:'0',width:'-webkit-fill-available',backgroundColor:'white'}}>
        <div class="copyright">
          &copy; Copyright{" "}
          <strong>
            <span>Art Wrap</span>
          </strong>
          . All Rights Reserved
        </div>
        <div class="credits">
          {/* <!-- All the links in the footer should remain intact. -->
       <!-- You can delete the links only if you purchased the pro version. -->
       <!-- Licensing information: https://bootstrapmade.com/license/ -->
       <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/nice-admin-bootstrap-admin-html-template/ --> */}
          Designed by <a href="#">Harshvardhan soni</a>
        </div>
      </footer>
    </section>
  );
};

export default Footer;
