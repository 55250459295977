import React, { useState } from "react";
import html2canvas from "html2canvas";
import { Rnd } from "react-rnd";

const StickerEditor = () => {
  const [mobileModelImage, setMobileModelImage] = useState(null); // Phone model
  const [sticker, setSticker] = useState(null); // User photo/sticker
  const [imageDimensions, setImageDimensions] = useState({ width: 300, height: 600 });

  // To track sticker's position/size/rotation
  const [stickerState, setStickerState] = useState({
    width: 100,
    height: 100,
    x: 50,
    y: 50,
    rotation: 0, // Rotation angle
  });

  const handleMobileModelImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          setImageDimensions({ width: img.width, height: img.height });
        };
        setMobileModelImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleStickerChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSticker(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRotationChange = (e) => {
    setStickerState((prevState) => ({
      ...prevState,
      rotation: e.target.value,
    }));
  };

  const handleCapture = () => {
    const element = document.querySelector("#sticker-container");
    html2canvas(element).then((canvas) => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = "sticker-preview.png";
      link.click();
    });
  };

  return (
    <main id="main" className="main">
      <section className="section">
        <div>
          <h2>Edit Your Image on iPhone</h2>

          {/* Mobile Model Image Upload */}
          <input type="file" accept="image/*" onChange={handleMobileModelImageChange} />
          <br />

          {/* Sticker Image Upload */}
          <input type="file" accept="image/*" onChange={handleStickerChange} />
          <br />

          {/* Container for Sticker and Mobile Model */}
          {mobileModelImage && (
            <div
              id="sticker-container"
              style={{
                position: "relative",
                width: `${imageDimensions.width}px`,
                height: `${imageDimensions.height}px`,
                margin: "20px auto",
                border: "1px solid #ccc",
              }}
            >
              {/* Sticker Image (draggable, resizable, and rotatable) */}
              {sticker && (
                <Rnd
                  size={{ width: stickerState.width, height: stickerState.height }}
                  position={{ x: stickerState.x, y: stickerState.y }}
                  onDragStop={(e, d) => {
                    setStickerState({ ...stickerState, x: d.x, y: d.y });
                  }}
                  onResizeStop={(e, direction, ref, delta, position) => {
                    setStickerState({
                      width: ref.style.width,
                      height: ref.style.height,
                      ...position,
                    });
                  }}
                  style={{
                    zIndex: 1,
                    transform: `rotate(${stickerState.rotation}deg)`,
                  }}
                >
                  <img
                    src={sticker}
                    alt="Sticker"
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "block",
                      opacity: 0.9,
                    }}
                  />
                </Rnd>
              )}

              {/* Transparent Mobile Model (Overlays the sticker) */}
              {mobileModelImage && (
                <img
                  src={mobileModelImage}
                  alt="Mobile Model"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 2,
                  }}
                />
              )}
            </div>
          )}

          {/* Rotate Sticker */}
          {sticker && (
            <>
              <label>Rotate Sticker: </label>
              <input
                type="range"
                min="0"
                max="360"
                value={stickerState.rotation}
                onChange={handleRotationChange}
              />
            </>
          )}

          {/* Save and Reset Buttons */}
          {mobileModelImage && sticker && (
            <>
              <button onClick={handleCapture}>Download Design</button>
              <button onClick={() => setStickerState({ width: 100, height: 100, x: 50, y: 50, rotation: 0 })}>
                Reset Sticker Position
              </button>
            </>
          )}
        </div>
      </section>
    </main>
  );
};

export default StickerEditor;
