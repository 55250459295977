import React from 'react'
import PropCreteatSkinproduct from '../../GenricComponnets/PropCreteatSkinproduct'
const CreateCustomizeskinproduct = () => {
  return (
    <div>
       <PropCreteatSkinproduct header={"Create Mobile Customize Skin Product"} endpoint={'InsertMobileskinCategorySubTypePod'} producttype={2}/>
    </div>
  )
}

export default CreateCustomizeskinproduct
