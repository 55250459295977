import React from 'react'

const BreadcrumpsPagetitle = ({heading,items}) => {
  return (
    <div className="pagetitle">
    <h1>{heading}</h1>
    <nav class="d-flex justify-content-end">
      <ol className="breadcrumb">
        {items.map((item, index) => (
          <li key={index} className="breadcrumb-item">
            <a href={item.Path}>{item.Name}</a>
          </li>
        ))}
        <li className="breadcrumb-item active">{heading}</li>
      </ol>
    </nav>
  </div>
  )
}

export default BreadcrumpsPagetitle
