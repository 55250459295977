import React from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Mainlayout from './Componnets/MasterComponents/Mainlayouts'
import Demopage from './Componnets/ChildrenComponnets/AdminComponnets/Demopage'
import Nopage from "./Componnets/OtherComponnets/Nopage";
import Login from "./Componnets/AuthComponnets/Login";
import AuthProvider from "./Services/Authservice/AuthProvider";
import Maintenceservice from './Services/Maintenceservice';
import AdminPrivateRoute from "./Services/Authservice/AdminPrivateRoute";
import Dashboard from "./Componnets/ChildrenComponnets/AdminComponnets/Dashboard";
import CreateSkinCollection from "./Componnets/ChildrenComponnets/AdminComponnets/CreateSkinCollection";
import CreateHeaderImage from './Componnets/ChildrenComponnets/AdminComponnets/CreateHeaderImage'
import CreateMobileBrand from './Componnets/ChildrenComponnets/AdminComponnets/CreateMobileBrand'
import CreateMobileBrandModel from './Componnets/ChildrenComponnets/AdminComponnets/CreateMobileBrandModel'
import CreateMobileCoverProduct from './Componnets/ChildrenComponnets/AdminComponnets/CreateMobileCoverProduct'
import CreatePromoCode from './Componnets/ChildrenComponnets/AdminComponnets/CreatePromoCode'
import CreateSkinCollectionType from './Componnets/ChildrenComponnets/AdminComponnets/CreateSkinCollectionType'
import CreateCustomizeskinproduct from "./Componnets/ChildrenComponnets/AdminComponnets/CreateCustomizeskinproduct";
import Detailsprivateroute from "./Services/Authservice/Detailsprivaterout";
import SkinCollectionDetails from "./Componnets/ChildrenComponnets/DetailsComponets/SkinCollectionDetails";
import HeaderImageDetail from './Componnets/ChildrenComponnets/DetailsComponets/HeaderImageDetail'
import MobileBrandDetails from './Componnets/ChildrenComponnets/DetailsComponets/MobileBrandDetails'
import MobileBrandModelsDetails from './Componnets/ChildrenComponnets/DetailsComponets/MobileBrandModelsDetails'
import MobileCoverProductDetails from './Componnets/ChildrenComponnets/DetailsComponets/MobileCoverProductDetails'
import PromoCodeDetail from './Componnets/ChildrenComponnets/DetailsComponets/PromoCodeDetail'
import SkinCollectionTypeDetails from './Componnets/ChildrenComponnets/DetailsComponets/SkinCollectionTypeDetails'
import MobileCustomizeSkinProductDetails from "./Componnets/ChildrenComponnets/DetailsComponets/MobileCustomizeSkinProductDetails";
import Sticker from "./Componnets/UtilisComponent/Sticker";
import Maintenance from "./Componnets/WebsiteComponents/Maintenance";
function App() {

  return (
    <div className="App">
      <Router>
          <AuthProvider>
            <Routes>
          
            <Route element={<Maintenceservice />}>
           
              {/* Authroziation Admin Private routes */}
                <Route element={<Mainlayout />}>
                  {/* Routes that should include Header and Footer */}
                  <Route element={<AdminPrivateRoute />}>
                    <Route path="/Demopage" element={<Demopage />} />
                    <Route path="/Home/Admin/Dashboard" element={<Dashboard />} />
                    <Route path="/Home/Admin/CreateSkinCollection" element={<CreateSkinCollection />} />
                    <Route path="/Home/Admin/CreateSkinCollectionType" element={<CreateSkinCollectionType />} />
                    <Route path="/Home/Admin/CreateMobileBrand" element={<CreateMobileBrand />} />
                    <Route path="/Home/Admin/CreateMobileBrandModel" element={<CreateMobileBrandModel />} />
                    <Route path="/Home/Admin/CreateMobileCoverProduct" element={<CreateMobileCoverProduct />} />
                    <Route path="/Home/Admin/CreateHeaderImage" element={<CreateHeaderImage />} />
                    <Route path="/Home/Admin/CreatePromoCode" element={<CreatePromoCode />} />
                    <Route path="/Home/Admin/CreateMobileCustomizeSkinProduct" element={<CreateCustomizeskinproduct />} />
                    
                  </Route>
                  <Route element={<Detailsprivateroute />}>
                    <Route path="/Home/DetailsReports/SkinCollectionDetails" element={<SkinCollectionDetails />} />
                    <Route path="/Home/DetailsReports/SkinCollectionTypeDetails" element={<SkinCollectionTypeDetails />} />
                    <Route path="/Home/DetailsReports/MobileBrandDetails" element={<MobileBrandDetails />} />
                    <Route path="/Home/DetailsReports/MobileBrandModelsDetails" element={<MobileBrandModelsDetails />} />
                    <Route path="/Home/DetailsReports/MobileCoverProductDetails" element={<MobileCoverProductDetails />} />
                    <Route path="/Home/DetailsReports/HeaderImageDetail" element={<HeaderImageDetail />} />
                    <Route path="/Home/DetailsReports/PromoCodeDetail" element={<PromoCodeDetail />} />
                    <Route path="/Home/DetailsReports/MobileCustomizeSkinProductDetails" element={<MobileCustomizeSkinProductDetails />} />
                  </Route>
                </Route>

                {/* Routes that should not include Header and Footer */}
                <Route path="/Sticker" element={<Sticker />} />

                <Route path="/" element={<Login />} />
                {/* no path */}
                <Route path="*" element={<Nopage />} />
                </Route>
              <Route path="/Maintenance" element={<Maintenance />} />

             
            </Routes>
          </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
