import React, { useEffect } from 'react'
import { useAuth } from '../../Services/Authservice/AuthProvider'
const Nopage = () => {
  const auth=useAuth();
  useEffect (() => {
       auth.logOut();
  });
  return (
    <section class="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center">
    <h1>404</h1>
    <h2>The page you are looking for doesn't exist.</h2>
    <a class="btn" href="/">Back to Login Page</a>
    <img src="assets/img/not-found.svg" class="img-fluid py-5" alt="Page Not Found"/>
    <div class="credits">
       Designed by <a href="#">Harshvardhan soni</a>
    </div>
  </section>
  )
}

export default Nopage
