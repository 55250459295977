import React from 'react';
import './Loading.css'

const LoadingPopUp = ({ show }) => {
  return (
    <div className={`modal ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} tabIndex="-1" role="dialog" data-bs-backdrop="static">
      <div className="modal-dialog  modal-fullscreen bk" >
        <div className="modal-content bk">
          
          <div className="modal-body divM">
                <svg className='svg' width="200" height="200" id="svg">
                <circle id="dot1" class="shape" />
                <circle id="dot2" class="shape" />
                <circle id="dot3" class="shape" />
                <circle id="dot4" class="shape" />
                </svg>
                
                
          </div>
         
        </div>
      </div>
    </div>
  );
};

export default LoadingPopUp;
