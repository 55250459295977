import React, { useState,useEffect } from "react";
import BreadcrumpsPagetitle from "../../GenricComponnets/BreadcrumpsPagetitle";
import {
  showErrorAlert,
  showAlert,
  showSuccessAlert,
} from "../../UtilisComponent/sweatalert";
import useApiService from "../../../Services/ApiService/apiService";
import LoadingPopup from "../../UtilisComponent/LoadingComponent/LoadingPopUp";
import { useAuth } from "../../../Services/Authservice/AuthProvider";
const CreatePromoCode = () => {
    let Heading = "Create Promo Code";
    let childPath = [
      { Name: "Home", Path: "#" },
      { Name: "Admin", Path: "#" },
    ];
    const [isloading, setisloading] = useState(false);
    const { postData } = useApiService();
    const { Token } = useAuth();
   
    // Define initial form state
    const initialFormState = {
      promoCode: '',
      influencerSocialMediaID: '',
      socialMediaName: '',
      discountPercantage: 0
    };
  
      const [formData, setFormData] = useState(initialFormState);
      const validateFormData = () => {
        const response ={status :false,msg:'NOT VALID'}
       
        if (!formData.promoCode) {
         response.msg="Please Fill promoCode.";
          return response;
        }
        if (!formData.influencerSocialMediaID) {
         response.msg="influencer SocialMedia ID cannot be null.";
          return response;
        }
        if (!formData.socialMediaName) {
          response.msg=" SocialMedia cannot be null.";
           return response;
         }
         if (!formData.discountPercantage || isNaN(formData.discountPercantage)) {
          response.msg=" discountPercantage cannot be 0 or null.";
           return response;
         }
       
        response.msg='';
        response.status=true;
        return response; // Validation successful
      };
  
     
  
    const submit = async (e) => {
      debugger;
      e.preventDefault();
      setisloading(true)
      try {
        const VALIDfORMDATA= validateFormData();
        if (VALIDfORMDATA.status) {
        
          const response = await postData(
            `Distributor/InsertPromoCode`,
            formData,
            false,
            Token
          );
          if (response) {
            if (response.statusCode === 200) {
              const res = response.data;
              const status = res.status;
              if (status) {
                setFormData(initialFormState);
                showSuccessAlert("Wooho !! New Promo Created.");
              } else {
                showErrorAlert(res.message);
              }
            } else {
              showAlert(response.responseMessage);
            }
          } else {
            showErrorAlert("Something went wrong ,please Contact To It Team.");
          }
        }
       else {
          showAlert(VALIDfORMDATA.msg);
        }
      } catch (err) {
        showErrorAlert(err);
      } finally {
        setisloading(false);
      }
    };
    const reset = async (e) => {
      e.preventDefault();
      setFormData(initialFormState);
    };
    return (
      <main id="main" class="main">
      <LoadingPopup show={isloading} />
        <BreadcrumpsPagetitle heading={Heading} items={childPath} />
        <section class="section dashboard">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">&NBSP;</h5>

                  <form class="row g-3">

                    <div class="col-md-4">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          value={formData.promoCode}
                          onChange={(e) => setFormData({ ...formData, promoCode: e.target.value })}
                          name="collectionsName"
                          placeholder="promoCode"
                        />
                        <label for="floatingPassword">Promo Code</label>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          value={formData.influencerSocialMediaID}
                          onChange={(e) => setFormData({ ...formData, influencerSocialMediaID: e.target.value })}
                          name="collectionsName"
                          placeholder="influencerSocialMediaID"
                        />
                        <label for="floatingPassword">Influencer ID</label>
                      </div>
                    </div>  <div class="col-md-4">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          value={formData.socialMediaName}
                          onChange={(e) => setFormData({ ...formData, socialMediaName: e.target.value })}
                          name="collectionsName"
                          placeholder="Social Media Plantform Name"
                        />
                        <label for="floatingPassword">Social Media Platform Name</label>
                      </div>
                    </div>  <div class="col-md-4">
                      <div class="form-floating">
                        <input
                          type="number"
                          class="form-control"
                          id="name"
                          value={formData.discountPercantage}
                          onChange={(e) => setFormData({ ...formData, discountPercantage: e.target.value })}
                          name="collectionsName"
                          placeholder="discountPercantage"
                        />
                        <label for="floatingPassword">Discount Percantage</label>
                      </div>
                    </div>
                  
                    
                      <div class="text-center">
                        <button
                          type="submit"
                          class="btn btn-primary"
                          onClick={submit}
                        >
                          Submit
                        </button>
                        &nbsp;
                        <button
                          type="reset"
                          class="btn btn-secondary"
                          onClick={reset}
                        >
                          Reset
                        </button>
                      </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
}

export default CreatePromoCode
