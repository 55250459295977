import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../Services/Authservice/AuthProvider';
import { showErrorAlert,showAlert } from '../UtilisComponent/sweatalert';
const Login = () => {
  const auth = useAuth();

    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const [input, setInput] = useState({
      username: "",
      password: "",
    });
    const [isloading,setisloading] = useState(false);
   
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    const handleInput = (e) => {
      const { name, value } = e.target;
      setInput((prev) => ({
        ...prev,
        [name]: value,
      }));
    };
  
    const handleSubmit = async (e) => {
      
      setisloading(true)
    try{
      if (input.username !== "" && input.password !== "") {
       
        const apiResponse =await auth.loginAction(input);
        if(apiResponse.errorCode==100){
          navigate("/Home/Admin/Dashboard");
          return;
        }
        else{
          showAlert(apiResponse.errorMsg);
          return;
        }
      }
      showAlert("pleae provide a Username or Password");
      return;
    }
    catch(err){
      showErrorAlert(err.message)
    }
    finally{
      setisloading(false)
    }
    };
    
    
  return (
    <div>
        <main>
    <div class="container">
      <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center" style={{marginTop:'-75px'}}>

              <div class="d-flex justify-content-center py-4">
                <a href="index.html" class="logo d-flex align-items-center w-auto">
                  <h1 className='.btn-primary' style={{fontWeight:'bold'}}>ArtWrap</h1>{/* <img src="assets/img/Virtuzo.png" alt=""></img> */}
                  <span class="d-none d-lg-block"></span>
                </a>
              </div>

              <div class="card mb-3">

                <div class="card-body">

                  <div class="pt-4 pb-2">
                    <h5 class="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                    <p class="text-center small">Enter your username & password to login</p>
                  </div>

                  <form class="row g-3 needs-validation" novalidate>

                    <div class="col-12">
                      <label for="yourUsername" class="form-label">Username</label>
                      <div class="input-group has-validation">
                        <span class="input-group-text" id="inputGroupPrepend">@</span>
                        <input type="text" name="username" class="form-control" id="yourUsername" onChange={handleInput} required></input>
                        <div class="invalid-feedback">Please enter your username.</div>
                      </div>
                    </div>

                    <div class="col-12">
                      <label for="yourPassword" class="form-label">Password</label>
                      <div class="input-group has-validation">
                       
                        <input type={showPassword ? 'text' : 'password'} name="password" onChange={handleInput} class="form-control" id="yourPassword"  required></input>
                        <span
                          className={`input-group-text ${showPassword ? 'visible' : ''}`} id="inputGroupPrepend"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? <i className="bi bi-eye-fill"></i> :
                                        <i className="bi bi-eye-slash-fill"></i> 
                                      }
                        </span>
                        <div class="invalid-feedback">Please enter your password!</div>
                      </div>
                     
                    </div>

                   
                    <div class="col-12">
                    <button
        className="btn btn-primary w-100"
        type="submit"
        onClick={handleSubmit}
        disabled={isloading} // Disable the button when loading
      >
        {isloading ? <label>Loading&nbsp;<i className='fa fa-spinner fa-spin'></i></label>  : <label>Login &nbsp;<i className='fa fa-sign-in '></i></label>}
      </button>
                    </div>
                    <div class="col-12" style={{display:'none'}}>
                      <p class="small mb-0">Don't have account? <a href="/Register"  >Create an account</a></p>
                    </div>
                   
                  </form>

                </div>
              </div>

              <div class="credits"  >
                Designed by <a href="#">HarshVardhan soni</a>
              </div>

            </div>
          </div>
        </div>

      </section>
      
     
    </div>
  </main>
    </div>
  )
}

export default Login
