// GLOBAL  for productionVALUES
const config = {
     apiUrl: 'https://goldsmithart.in/api/',
     apiHostUrl:'https://goldsmithart.in/',//Its key used for showing photo'https://localhost:7235/api/',
     websitdown:1
  };
 export default config;
  

//  For testting config
// const config = {
//   apiUrl: 'https://localhost:7235/api/',
//   apiHostUrl:'https://localhost:7235/',//Its key used for showing photo'https://localhost:7235/api/',
//   websitdown:1
// };
// export default config;