import React, { useEffect,useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../Services/Authservice/AuthProvider";
import { getData } from "../../../Services/ApiService/apiService";
import { showAlert,showErrorAlert } from "../../UtilisComponent/sweatalert";
import useApiService from "../../../Services/ApiService/apiService";
const Aside = () => {
  const { getData } = useApiService();
    
      const {Token,Role} = useAuth();
      const [menuData, setMenuData] = useState([]);
  useEffect(() => {
    fetchMenuData();
  }, []);
  const fetchMenuData = async () => {
    try {
      const response=await getData(`Distributor/GetDealerMenuList/${Role}`,Token);
      if(response.statusCode===200) {
        const res = response.data;
        const status = res.status;
        if(status){
          setMenuData(res.data);
        }
        else{
          showAlert(res.message);
        }
      }
      else{
        showAlert(response.responseMessage);
      }
    } catch (error) {
     showErrorAlert(error);
    }
  };

 // Group menu items by MenuName
  const groupedMenu = menuData.reduce((acc, item) => {
    if (!acc[item.MenuName]) {
      acc[item.MenuName] = []; // Initialize an array for new menu names
    }
    acc[item.MenuName].push(item); // Push the current item into the corresponding array
    return acc;
  }, {});

  return (
    <div>
      {/* <!-- ======= Sidebar ======= --> */}
      <aside id="sidebar" class="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
      {Object.keys(groupedMenu).map((menuName, index) => {
        const items = groupedMenu[menuName];
        

        // Check if items is an array and has at least one element
        if (Array.isArray(items) && items.length > 0) {
          if (items.length > 1) {
            
            return (
              <li className="nav-item" key={index}>
                <Link
                  className="nav-link collapsed"
                  data-bs-target={`#${menuName.replace(/\s+/g, '-')}-nav`}
                  data-bs-toggle="collapse"
                  to="#"
                >
                  <i className={items[index].Icons}></i>
                  <span>{menuName}</span>
                  <i className="bi bi-chevron-down ms-auto"></i>
                </Link>
                <ul
                  id={`${menuName.replace(/\s+/g, '-')}-nav`}
                  className="nav-content collapse"
                  data-bs-parent="#sidebar-nav"
                >
                  {items.map((menuItem, itemIndex) => {
                    const submenuName = typeof menuItem.SubmenuName === 'string' ? menuItem.SubmenuName : null;
                    const menuPath = typeof menuItem.MenuPath === 'string' ? menuItem.MenuPath : '#';

                    // Skip rendering if submenuName is not valid
                    if (!submenuName) {
                      return null; // Do not render this item
                    }

                    return (
                      <li key={itemIndex}>
                        <Link
                          to={menuPath}
                          className={window.location.pathname === menuPath ? 'active' : ''}
                        >
                          <i className="bi bi-circle"></i>
                          <span>{submenuName}</span>
                        </Link>
                      </li>
                    );
                  })}

                </ul>
              </li>
            );
          } else {
            // If there's only one submenu, create a standalone menu item
            const singleItem = items[0];
            const singleSubmenuName = typeof singleItem.SubmenuName === 'string' ? singleItem.SubmenuName : null;
            const singleMenuPath = typeof singleItem.MenuPath === 'string' ? singleItem.MenuPath : '#';

            // Skip rendering if singleSubmenuName is not valid
            if (!singleSubmenuName) {
              return null; // Do not render this item
            }

            return (
              <li className="nav-item" key={index}>
                <Link
                  className="nav-link collapsed"
                  to={singleMenuPath}
                >
                  <i className={singleItem.Icons}></i>
                  <span>{singleSubmenuName}</span>
                </Link>
              </li>
            );
          }
        }

        return null; // Fallback if there are no items
      })}
      
    </ul>

      </aside>
    </div>
  );
};

export default Aside;
