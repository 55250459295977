import React, { useState, useEffect } from "react";
import BreadcrumpsPagetitle from "./BreadcrumpsPagetitle";
import {
  showErrorAlert,
  showAlert
} from "../UtilisComponent/sweatalert";
import useApiService from "../../Services/ApiService/apiService";
import LoadingPopUp from "../UtilisComponent/LoadingComponent/LoadingPopUp";
import { useAuth } from "../../Services/Authservice/AuthProvider";
import DataTable from "../UtilisComponent/DataTable";
const PropsMobileproductDetails = ({header,endpoint}) => {
    let Heading = header;
    let childPath = [
      { Name: "Home", Path: "#" },
      { Name: "Details Report", Path: "#" },
    ];
    const [isloading, setisloading] = useState(false);
    const { getData } = useApiService();
    const { Token } = useAuth();
    useEffect(() => {
      fetchmobilecoverProduct();
    },[endpoint]);
    // Define columns from the keys of the data dynamically
    const excludeColumns = ["ResponseCode","ResponseMsg", "IsActive","ProductType","CategoriesSubTypeImage"];
    const [mobilecoverProduct, setmobilecoverProduct] = useState([]);

    const fetchmobilecoverProduct = async () => {
      setisloading(true)
      setmobilecoverProduct([])
      try {
        const response=await getData(`Categories/${endpoint}`,Token);
        if(response.statusCode===200) {
          const res = response.data;
          const status = res.status;
          if(status){
            setmobilecoverProduct(res.data)
          }
          else{
            showAlert(res.message);
          }
        }
        else{
          showAlert(response.responseMessage);
        }
      } catch (error) {
       showErrorAlert(error);
      }
      finally{
        setisloading(false)
      }
    };
    return (
      <main id="main" class="main">
         <LoadingPopUp show={isloading} />
        <BreadcrumpsPagetitle heading={Heading} items={childPath} />
          <section class="section">
          <div class="row">
          <div class="col-lg-12">
              <DataTable data={mobilecoverProduct} Title={'Mobiles Skin Product'} excludeColumns={excludeColumns} 
                imageUrlColumn="CategoriesSubTypeImage" // Pass the dynamic column name for image
                showEditDeleteCondition={false} // Pass the condition for Edit/Delete buttons
                showImageCondition={true}
                />
          </div>
        </div>
        </section>
  
      </main>
    );
}

export default PropsMobileproductDetails
