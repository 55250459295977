import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import useApiService from "../ApiService/apiService";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const { getData, postData } = useApiService();
  const [user, setUser] = useState(localStorage.getItem("Username") || "");
  const [Role, setRole] = useState(localStorage.getItem("Role") || 0);
  const [Token, setToken] = useState(localStorage.getItem("token") || "");
  const navigate = useNavigate();

  const loginAction = async (data) => {
    let Response = {
      errorCode: 102,
      errorMsg: 'An unknown error occurred.',
    };
    try {
        const response=await postData('Authentication/DealerLogin', data);
       
      if(response.statusCode===200){
        const res = response.data;
        const status = res.status;
        if(status){
          const datamore = res.data;
          const token=datamore[0].Token;
          const Username=datamore[0].Username
          const Role=datamore[0].Role
          localStorage.setItem("token", token);
          localStorage.setItem("Username", Username);
          localStorage.setItem("Role", Role);
            setUser(Username);
            setToken(token);
            setRole(Role)
            //navigate("app/Dashboard");
            Response.errorCode=100;
            Response.errorMsg='Validation Pass'
            return Response;;
        }
        Response.errorCode=101;
        Response.errorMsg=res.message
        return Response;;
      }
      Response.errorCode=103;
      Response.errorMsg=response.responseMessage;
      return Response;
    } catch (err) {
      Response.errorCode=102;
      Response.errorMsg=err
      return Response;
    }
  };
  
  const logOut = () => {
    setUser(null);
    setToken("");
    setRole(0)
    localStorage.clear();
    navigate("/");
  };
  const TokenValidate = async () => {
    try {
      const response=await getData(`Authentication/TokenVerification`,Token);
      if(response.statusCode===200){
        return true;
      }
      return false;
    } catch (err) {
      console.log(err.message)
      return false
    }
  };
  return (
    <AuthContext.Provider value={{Token, user, loginAction, logOut,Role ,TokenValidate}}>
      {children}
    </AuthContext.Provider>
  );

};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};