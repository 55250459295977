import React, { useState, useEffect } from "react";
import BreadcrumpsPagetitle from "./BreadcrumpsPagetitle";
import {
  showErrorAlert,
  showAlert,
  showSuccessAlert,
} from "../UtilisComponent/sweatalert";
import useApiService from "../../Services/ApiService/apiService";
import LoadingPopUp from "../UtilisComponent/LoadingComponent/LoadingPopUp";
import { useAuth } from "../../Services/Authservice/AuthProvider";

const PropCreteatSkinproduct = ({header,endpoint,producttype}) => {
    let Heading = header ;
  let childPath = [
    { Name: "Home", Path: "#" },
    { Name: "Admin", Path: "#" },
  ];

  const [isloading, setisloading] = useState(false);
  const [categorydata, setcategorydata] = useState([]);
  const [categorytypedata, setcategorytypedata] = useState([]);
  

  const { getData, postData } = useApiService();
  const { Token } = useAuth();

  // Define initial form state with all fields
  const initialFormState = {
    CategoriesTypeID: producttype==1 ?0:1,
    CategoriesSubTypeName:'',
    CategoriesSubTypeImage:Math.random().toString(),
    CategoriesSubTypeImage1:Math.random().toString(),
    ShippingCharges: '',
    FakePrice: '',
    OriginalPrice: '',
    IsActive: true,
    ProductCode: '',
    Description: '',
    ListDescription: '',
    file: null,
    file1:null
  };

  const [formData, setFormData] = useState(initialFormState);

  useEffect(() => {
    if(producttype==1){
      fetchMobileSkinCategoryData();
    }
   
  },[producttype]);

  const fetchMobileSkinCategoryData = async () => {
    setisloading(true);
    setcategorydata([]);
    try {
      const response = await getData(`Categories/GetMobileskinCategories`, Token);
      if (response.statusCode === 200) {
        const res = response.data;
        const status = res.status;
        if(status){
          setcategorydata(res.data);
        }
        else{
          showAlert(res.message);
        }
      } else {
        showAlert(response.responseMessage);
      }
    } catch (error) {
      showErrorAlert(error);
    } finally {
      setisloading(false);
    }
  };

  const fetchMobileSkinCategoryTypeData = async (e) => {
    debugger
    setisloading(true);
    const selectedCategoryID = parseInt(e); // Store the selected value directly

    setcategorytypedata([]);

    try {
      const response = await getData(`Categories/GetMobileskinCategoryTypeById?CategroyId=${selectedCategoryID}`, Token);
      if (response.statusCode === 200) {
        const res = response.data;
        const status = res.status;
        if(status){
          setcategorytypedata(res.data);
        }
        else{
          showAlert(res.message);
        }
      } else {
        showAlert(response.responseMessage);
      }
    } catch (error) {
      showErrorAlert(error);
    } finally {
      setisloading(false);
    }
  };
  const validateFormData = () => {
    debugger
    // Check for all mandatory fields
    
    if (producttype==1 &&formData.CategoriesTypeID === 0) {
      return { status: false, msg: "Please select a Category Type." };
    }
    
    if (!formData.CategoriesSubTypeName) {
      return { status: false, msg: "Product Name is required." };
    }
   
    if (!formData.Description) {
      return { status: false, msg: "Product description is required." };
    }
    if (!formData.ListDescription) {
      return { status: false, msg: "List description is required." };
    }
    
    // Validate ListDescription for at least two commas
    const commaCount = formData.ListDescription.split(',').length - 1;
    if (commaCount < 2) {
      return { status: false, msg: "List description must contain at least two comma-separated values." };
    }
  
    if (!formData.FakePrice || isNaN(formData.FakePrice)) {
      return { status: false, msg: "Valid fake price is required." };
    }
    if (!formData.OriginalPrice || isNaN(formData.OriginalPrice)) {
      return { status: false, msg: "Valid original price is required." };
    }
  
    // Validate that FakePrice is greater than OriginalPrice
    if (isNaN(formData.OriginalPrice )> isNaN(formData.FakePrice)) {
      return { status: false, msg: "Fake price must be greater than the original price." };
    }
  
    if (!formData.ShippingCharges || isNaN(formData.ShippingCharges)) {
      return { status: false, msg: "Valid shipping charges are required." };
    }
    if (!formData.file) {
      return { status: false, msg: "Image file 1 must be selected." };
    }
    if (!formData.CategoriesSubTypeImage1) {
      return { status: false, msg: "Image file 2 must be selected." };
    }
  
    return { status: true, msg: "" };
  };
  

  const handleFileChange = (e, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: e.target.files[0],
    });
  };

  const submit = async (e) => {
    e.preventDefault();
    debugger
    const validData = validateFormData();
    if (!validData.status) {
      showAlert(validData.msg);
      return;
    }

    setisloading(true);
    try {
      const data = new FormData();
      data.append("CategoriesSubTypeName", formData.CategoriesSubTypeName);
      data.append("CategoriesSubTypeImage", formData.CategoriesSubTypeImage);
      data.append("CategoriesSubTypeImage1", formData.CategoriesSubTypeImage1);
      data.append("CategoriesTypeID", formData.CategoriesTypeID);
      data.append("ShippingCharges", formData.ShippingCharges);
      data.append("FakePrice", formData.FakePrice);
      data.append("OriginalPrice", formData.OriginalPrice);
      data.append("ProductCode", formData.ProductCode);
      data.append("Description", formData.Description);
      data.append("ListDescription", formData.ListDescription);
      data.append("file", formData.file);
      data.append("file1", formData.file1);
      data.append("IsActive", formData.IsActive);

      const response = await postData(
        `Distributor/${endpoint}`,
        data,
        true,
        Token
      );

      if (response && response.statusCode === 200) {
        const res = response.data;
        const status = res.status;
        if(status){
          showSuccessAlert("Product created successfully!");
          setFormData(initialFormState);
        }
        else{
          showAlert(res.message);
        }
      } else {
        showAlert(response.responseMessage);
      }
    } catch (error) {
      showErrorAlert("Failed to create product."+error);
    } finally {
      setisloading(false);
    }
  };
  const reset = async (e) => {
    e.preventDefault();
    setFormData(initialFormState);
  };
  return (
    <main id="main" className="main">
      <LoadingPopUp show={isloading} />
      <BreadcrumpsPagetitle heading={Heading} items={childPath} />
      <section className="section dashboard">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Create Product</h5>

                <form className="row g-3" onSubmit={submit}>
                {producttype==1&&
                  <div className="col-md-4">
                    <div className="form-floating mb-3">
                      <select
                        className="form-select"
                        onChange={(e) =>
                          fetchMobileSkinCategoryTypeData(e.target.value)
                        }
                      >
                        <option value={0}>Select Collection </option>
                        {categorydata.map((type) => (
                          <option
                            key={type.CategoryID}
                            value={type.CategoryID}
                          >
                            {type.CategoryName}
                            {/* {type['Collection Type Name']} */}
                          </option>
                        ))}
                      </select>
                      <label>Collection </label>
                    </div>
                  </div>}

                  {producttype==1&&<div className="col-md-4">
                     <div className="form-floating mb-3">
                      <select
                        className="form-select"
                        value={formData.CategoriesTypeID}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            CategoriesTypeID: parseInt(e.target.value),
                          })
                        }
                      >
                        <option value={0}>Select Collection Type</option>
                        {categorytypedata.map((type) => (
                          <option
                            key={type.CategoriesTypeID}
                            value={type.CategoriesTypeID}
                          >
                            {type['Categories Type Name']}
                          </option>
                        ))}
                      </select>
                      <label>Collection Type</label>
                    </div>
                    </div>
                 }
                  
                  <div className="col-md-4">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        value={formData.CategoriesSubTypeName}
                        onChange={(e) =>
                          setFormData({ ...formData, CategoriesSubTypeName: e.target.value })
                        }
                        placeholder="Product Name"
                      />
                      <label>Product Name</label>
                    </div>
                  </div>
                  
                  <div className="col-md-4">
                    <div className="form-floating mb-3">
                      <textarea
                        className="form-control"
                        value={formData.Description}
                        onChange={(e) =>
                          setFormData({ ...formData, Description: e.target.value })
                        }
                        placeholder="Product Description"
                      />
                      <label>Product Description</label>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-floating mb-3">
                      <textarea
                        className="form-control"
                        value={formData.ListDescription}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            ListDescription: e.target.value,
                          })
                        }
                        placeholder="List Description"
                      />
                      <label>List Description</label>
                    </div>
                  </div>

                 

                  <div className="col-md-4">
                    <div className="form-floating">
                      <input
                        type="number"
                        className="form-control"
                        value={formData.ShippingCharges}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            ShippingCharges: e.target.value,
                          })
                        }
                        placeholder="Shipping Charges"
                      />
                      <label>Shipping Charges</label>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-floating">
                      <input
                        type="number"
                        className="form-control"
                        value={formData.FakePrice}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            FakePrice: e.target.value,
                          })
                        }
                        placeholder="Fake Price"
                      />
                      <label>Fake Price</label>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-floating">
                      <input
                        type="number"
                        className="form-control"
                        value={formData.OriginalPrice}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            OriginalPrice: e.target.value,
                          })
                        }
                        placeholder="Original Price"
                      />
                      <label>Original Price</label>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-floating">
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e) => handleFileChange(e, "file")}
                      />
                      <label>Image 1</label>
                    </div>
                  </div>

                 { <div className="col-md-4">
                    <div className="form-floating">
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e) => handleFileChange(e, "file1")}
                      />
                      <label>Image 2</label>
                    </div>
                  </div>
                  }
                 
                  <div class="text-center">
                  <button type="submit" className="btn btn-primary">
                      Create Product
                    </button> 
                    &nbsp;
                        <button
                          type="reset"
                          class="btn btn-secondary"
                          onClick={reset}
                        >
                          Reset
                        </button>
                  </div>
                   
               
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default PropCreteatSkinproduct
