import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import {useAuth} from './AuthProvider'

const AdminPrivateRoute = () => {
    const {TokenValidate,Role} = useAuth();
    if (!TokenValidate()) 
      return <Navigate to="/" />;
    if (Role!=0&&Role!=1) 
        return <Navigate to="/" />;
    return <Outlet />;
}

export default AdminPrivateRoute
