import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import config from "../config";
const Maintenceservice = () => {
    const down = config.websitdown;
    if (down!=1) 
        return <Navigate to="/Maintenance" />;
    return <Outlet />;
}

export default Maintenceservice