import React from "react";
import PropsMobileproductDetails from "../../GenricComponnets/PropsMobileproductDetails";


const MobileCoverProductDetails = () => {

    return (
      <>
      <PropsMobileproductDetails header={"Mobile Skin Product Details"} endpoint={'GetMobileskinCategorySubTypes'}/>
      </>
    );
}

export default MobileCoverProductDetails
